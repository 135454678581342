import { IntegrationModel, TeamModel } from "./team.model";

export enum RoleType {
  MANAGER = "Manager",
  PLAYER = "Player",
}
export interface UserInfoModel {
  email: string;
  emailnotifications: boolean;
  firstName: string;
  lastName: string;
  photo: string | null;
  sport: string | null;
  teams: number;
  tier: number;
  id: string;
  roles: {id: any, name: string}[];
  following: number;
  bio?: any;
  verified?: boolean;
  rank?: number;
  logins?: number;
}

export interface UserInfoModelWithTeam {
  team: {
    number: string;
    position: { id: string; name: string};
    team: TeamModel;
  }
  player: UserInfoModel;
};

export interface UserStatusModel {
  activated: boolean;
  message: string | null;
}

export interface UserSportModel {
  id: number;
  name: string;
  positions: PlayerPosition[];
  characteristics: PlayerCharacteristics[];
}

export interface PlayerPosition {
  id: number;
  name: string;
  fullName?: string;
  category?: string;
  categoryCode?: string;
}
export interface PlayerCharacteristics {
  id: number;
  name: string;
  abbrev: string;
  color: string;
  definition: string | null;
  desc: string | null;
  active: boolean;
}

export interface UserModel {
  id: string;
  status: UserStatusModel;
  info: UserInfoModel;
  sport: UserSportModel;
  teams: TeamModel[];
  players: any[];
  integrations: IntegrationModel[];
  notifications: number;
}

export enum LocalStorageEntities {
  user_info = "user_info",
  user_auth = "user_auth",
  selected_team = "selectedTeam",
  selected_player = "selectedPlayer",
}

export interface User {
  email: string;
  name: string;
  roles: string;
}

export interface LoginBody {
  user: string;
  pass: string;
}

export interface EditUserProfileModel
  extends Omit<UserInfoModel, "sport" | "teams" | "tier" | "photo" | "roles"> {
  photo: Blob | string | null;
  role: string;
}

export enum MediaTypes {
  INSTA = "instagram",
  TWITTER = "twitter",
  NO_MEDIA = "",
}

export interface QRCodeModel {
  playerQR: {
    url: string;
    label: string;
  },
  managerQR: {
    url: string;
    label: string;
  }
}
